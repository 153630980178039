import { Link, useLocation, useNavigate } from 'react-router-dom';
import {useEffect, useState, useContext} from 'react';
import { LanguageContext } from "../../lib/multilingualContext";

export default function Navbar () {
  const {currentLang, translatedData, toggleLanguage} = useContext(LanguageContext); 
  const location = useLocation();
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const [activeNav, setActiveNav] = useState(null);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const menu = translatedData.navBar.nav;
  
  function toggleNavMobileHandler() {
    if(window.innerWidth < 768){
      var navbarSupportedContent = document.getElementById('navbarSupportedContent')
      new window.bootstrap.Collapse(navbarSupportedContent, {
        toggle: true
      })
      setIsOpenNav(!isOpenNav);
    }
  }

  useEffect(() => {
    const handleScroll = event => {
      setScrolled(window.scrollY >= 10);
    };

    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect( () => {
    setActiveNav(location.pathname+location.hash);
  }, [location] )

  useEffect( () => {
    isOpenNav ? document.getElementsByTagName("html")[0].style.overflow = "hidden" : document.getElementsByTagName("html")[0].style.overflow = "visible";
  }, [isOpenNav] )
  
  return (
    <div className={`navigation-wrap start-header ${isOpenNav ? "bg-black":""} ${scrolled ? "scroll-on bg-black" : "start-style"}`}>
      <div className="ui-container">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-md">
            
              <Link className="navbar-brand" to={translatedData.navBar.navLogoUrl}><img src={require("../../assets/img/logo_dav_small.png")} alt=""/></Link>  
              
              <div className="order-md-3 d-flex align-items-center">
                <div className="d-flex">
                  <div onClick={() => toggleLanguage("id")} className={`${currentLang === "id" && "active"} btn-lang`}>
                    <div>ID</div>
                  </div>
                  <div onClick={() => toggleLanguage("en")} className={`${currentLang === "en" && "active"} btn-lang`}>
                    <div>EN</div>
                  </div>
                </div>
                
                <button className="navbar-toggler" type="button" aria-expanded={isOpenNav} aria-label="Toggle navigation" onClick={() => toggleNavMobileHandler()}>
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              
              <div className={`collapse navbar-collapse menu`} id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto py-4 py-md-0">
                  {
                    menu.map((item, key)=>(
                      <li key={key} className={`nav-item px-0 px-md-4 ${activeNav == item.url && "active"}`}>
                        <Link className="nav-link text-uppercase" onClick={()=>toggleNavMobileHandler()} to={item.url}>{item.name}</Link>
                      </li>
                    ))
                  }
                </ul>
              </div>

            </nav>    
          </div>
        </div>
      </div>
    </div>
  )
}