const enData = {
  //NAVBAR
  navBar: {
    navLogoUrl: '/',
    nav: [
      { name: 'HOW IT WORKS & NEWS', url: '/#how-it-works-and-news' },
      { name: 'LOCATION', url: '/location' },
      { name: 'BRANDS', url: '/#brands' },
      { name: 'Contact', url: '/#contact' },
    ],
  },
  //SERVICES
  servicesHome: {
    title:
      'DÄV is the leading IoT platform provider that leverage Augmented Reality (AR) + Artificial Intelligence (AI) + Internet of Things (IoT) application technology',
    desc: [
      'We aim to provide solutions through our kiosks that cover multiple scenarios in 2nd to 4th cities and also to reach sub-urban areas in Indonesia.',
      'DÄV, with its patented technology has been partnering with top retailers in Indonesia and serving global brands.',
    ],
  },
  //WORKS
  worksHome: {
    title: 'HOW IT WORKS AND NEWS',
    tutorialBtn: 'HOW IT WORKS',
    newsBtn: 'NEWS',
    modalNews: {
      title:
        'BAF Kiosk Offers Submission of Financing Practically and Informatively',
      desc: [
        "The development of Indonesia's digital economy has changed consumer behavior. One of them, the tendency of people to use digital platforms. To answer a need community, PT Bussan Auto Finance (BAF) in collaboration with DAV at 232 points on the island of Java, to help make it easier for potential customers to be able to submit dream products. Presence BAF at DAV will offer a practical and informative financing application experience.",
        'Specifically for the first 2,000 potential customers who apply for financing and include valid data via DAV, will automatically get an Alfamart shopping voucher worth IDR 25,000 during the program period. Not only that, for potential customers who apply for financing through BAF Kiosk and are approved will have the opportunity to get additional BAF Points cashback worth a total of IDR 50 million.',
        'The attractive promo is only valid for submissions via DAV, If interested in getting this attractive promo, consumers can simply apply for financing on the DAV machine at nearest Alfamart outlet',
        'Source',
        'https://surabaya.tribunnews.com/2022/11/11/baf-kiosk-tawarkan-pengajuan-pembiayaan-secara-praktis-dan-informatif',
      ],
    },
  },
  //LOCATIONS
  locationHome: {
    title: 'LOCATIONS',
    desc: 'DAV has evolved to an all-in-one digital kiosk solution delivering shopper engagement, operational efficiencies and bottom-line results at various public locations such as minimarkets, hotels, pharmacies, airports. DAV is enabler O2O between Brand and Consumer needs.',
    subTitle: 'Find us at',
    btnDetail: 'btn_location.png',
    urlDetail: '/location',
  },
  locationDetail: {
    title: 'LOCATION DETAILS',
    desc: 'DAV has evolved to an all-in-one digital kiosk solution delivering shopper engagement, operational efficiencies and bottom-line results at various public locations such as minimarkets, hotels, pharmacies, airports. DAV is enabler O2O between Brand and Consumer needs.',
    titleUpdate: 'Last update : ',
    dateUpdate: 'September 11, 2023',
    note: 'More locations to be listed periodically',
  },
  //CLIENT
  clientHome: {
    title: 'CLIENTS/PARTNERS HIGHLIGHT',
  },
  //CONTACT
  contactHome: {
    title: 'CONTACT US',
    titleAddress: 'Address',
    titlePhone: 'Phone',
  },
};

export default enData;
