import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { LanguageContext } from '../../lib/multilingualContext';

export default function Footer() {
  const { translatedData } = useContext(LanguageContext);
  const location = useLocation();
  const [activeNav, setActiveNav] = useState(null);
  const menu = translatedData.navBar.nav;

  useEffect(() => {
    setActiveNav(location.pathname + location.hash);
  }, [location]);

  return (
    <footer className="text-center">
      <div className="ui-container">
        <div className="nav-footer mx-auto mb-5 menu">
          <ul className="d-flex justify-content-between flex-column flex-md-row">
            {menu.map((item, key) => (
              <li
                className={` text-uppercase ${
                  activeNav === item.url && 'active'
                }`}
                key={key}
              >
                <Link to={item.url}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="social mb-md-5 mb-4">
          <a
            rel="noreferrer"
            href="https://www.youtube.com/@dav9370"
            target={'_blank'}
            className="mx-2"
          >
            <img src={require('../../assets/img/ic_yt.png')} alt="" />
          </a>
          <a
            rel="noreferrer"
            href="https://twitter.com/DAV_global"
            target={'_blank'}
            className="mx-2"
          >
            <img src={require('../../assets/img/ic_twt.png')} alt="" />
          </a>
          <a
            rel="noreferrer"
            href="https://www.instagram.com/dav_global/"
            target={'_blank'}
            className="mx-2"
          >
            <img src={require('../../assets/img/ic_ig.png')} alt="" />
          </a>
        </div>
        <a
          rel="noreferrer"
          href="https://wir.group/en/"
          target={'_blank'}
          className="mb-5 d-block wir-unit"
        >
          <img src={require('../../assets/img/unit_of_wir.png')} alt="" />
        </a>
        <p className="copyright">Copyright © DÄV. All Rights Reserved.</p>
      </div>
    </footer>
  );
}
