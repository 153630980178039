import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <div className="not-found-wrapper">
      <div className="ui-container text-center">
        <h1>404</h1>
        <h2 className="mt-3">Sorry we can't find that page!</h2>
        <Link className="mt-5 mx-auto" to="/">BACK TO HOME</Link>
      </div>
    </div>
  )
}