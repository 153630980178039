import { Link, useLocation } from 'react-router-dom';
import {useEffect, useState, useRef, useContext} from 'react';
import { LanguageContext } from "../lib/multilingualContext";

export default function Home() {
  const {translatedData} = useContext(LanguageContext); 
  const location = useLocation();
  const [showVideo, setShowVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState("https://www.youtube.com/embed/E3hpJV5TCFA");
  
  const howWorksSection = useRef(null);
  const brandsSection = useRef(null);
  const contactSection = useRef(null);

  function scrollToComponent() {
    let section = 0

    switch(window.location.hash) {
      case "#how-it-works-and-news":
        section = howWorksSection.current.offsetTop;
        break;
      case "#brands":
        section = brandsSection.current.offsetTop;
        break;
      case "#contact":
        section = contactSection.current.offsetTop;
        break;
      default:
        section = 0;
    }
    
    window.scrollTo({
      top: section - 70,
      behavior: 'smooth',
    });
  }

  useEffect( () => {
    scrollToComponent();
  }, [location] )

  return (
    <>
      <section className="jombotron">
        <div className="ui-container h-100 jb-wrap d-flex justify-content-center align-items-center">
          <div className="text-center">
            <img src={require("../assets/img/logo_dav_big.png")} alt=""/> 
            <h2 className="mt-4">GOOD SOLUTION IOT DEVICE</h2>
          </div>
        </div>
      </section>
      <section className="services position-relative">
        <div className="ui-container">
          <div className="ornament position-absolute">
            <img src={require("../assets/img/ornament_black.png")} alt=""/>
          </div>
          <div className="row py-md-4 py-5">
            <div className="col-md-5 col-12 order-2 order-md-1 ct-left">
              <h3 className="mb-4 mt-4">
                {translatedData.servicesHome.title}
              </h3>
              <div>
                <p className="mb-3">
                  {translatedData.servicesHome.desc[0]}
                </p>
                <p className="mb-4">
                  {translatedData.servicesHome.desc[1]}
                </p>
              </div>
              <img src={require("../assets/img/icons.png")} alt=""/>
            </div>
            <div className="col-md-7 col-12 order-1 order-md-2 ct-right">
              <img src={require("../assets/img/device.png")} alt=""/>
            </div>
          </div>
        </div>
      </section>
      <section ref={howWorksSection} className="works-news-container overflow-hidden position-relative">
        <div className="ornament position-absolute">
          <img src={require("../assets/img/ornament_green.png")} alt=""/>
        </div>
        <div className="ui-container">
          <h3 className="text-uppercase">{translatedData.worksHome.title}</h3>
        </div>
        <div className="row gx-3">
          <div className="col-md-6 col-12">
            <div className="card-item position-relative" data-bs-toggle="modal" data-bs-target="#videoModal" onClick={()=>setShowVideo(true)}>
              <img src={require("../assets/img/how-it-works.png")} alt=""/>
              <div className="label-arrow position-absolute start-0 text-uppercase">
              {translatedData.worksHome.tutorialBtn}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="card-item position-relative" data-bs-toggle="modal" data-bs-target="#newsModal">
              <img src={require("../assets/img/news.png")} alt=""/>
              <div className="label-arrow position-absolute start-0 text-uppercase">
              {translatedData.worksHome.newsBtn}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="location-content" className="location-container position-relative pb-5">
        <div className="ornament position-absolute">
          <img src={require("../assets/img/ornament_orange.png")} alt=""/>
        </div>
        <div className="ui-container">
          <h3 className="text-uppercase">{translatedData.locationHome.title}</h3>
        </div>
        <div className="d-flex justify-content-end">
          <img className="img-map me-0" src={require("../assets/img/peta.png")} alt=""/>
        </div>
        <div className="ui-container">
          <p className="desc text-center mx-auto my-5">
            {translatedData.locationHome.desc}
          </p>
          <h4 className="text-center">{translatedData.locationHome.subTitle}</h4>
          <div className="d-flex justify-content-evenly align-items-center partner mx-auto my-5">
            <img src={require("../assets/img/logo_alfamart.png")} alt=""/>
            <img src={require("../assets/img/logo_alfamidi.png")} alt=""/>
            <p>and Others</p>
          </div>
          <Link className="d-block btn-location mx-auto mb-3" to={translatedData.locationHome.urlDetail}>
            <img src={require("../assets/img/"+translatedData.locationHome.btnDetail)} alt=""/>
          </Link>
        </div>
      </section>
      <section ref={brandsSection} className="brands-container">
        <div className="ornament position-absolute">
          <img src={require("../assets/img/ornament_purple.png")} alt=""/>
        </div>
        <div className="ui-container pb-5">
          <h3 className="text-uppercase">{translatedData.clientHome.title}</h3>
          <img className="w-100" src={require("../assets/img/clients.png")} alt=""/>
        </div>
      </section>
      <section ref={contactSection} className="contact-container">
        <div className="ui-container pb-5">
          <h3 className="text-uppercase">{translatedData.contactHome.title}</h3>
          <div className="row">
            <div className="col-md-7 col-12">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe className="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=WIR Group HQ&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 pt-3 pt-md-0">
              <div className="contact-item">
                <h4 className="mb-1">{translatedData.contactHome.titleAddress}</h4>
                <p>
                  WIR Group HQ<br/>
                  Sequis Tower, 9th Floor<br/>
                  Sudirman Central Business District (SCBD)<br/>
                  Jl. Jend. Sudirman Kav. 71<br/>
                  Jakarta 12190<br/>
                  Indonesia
                </p>
              </div>
              <div className="contact-item">
                <h4 className="mb-1">{translatedData.contactHome.titlePhone}</h4>
                <p>+62 21 5080 8151</p>
              </div>
              <div className="contact-item">
                <h4 className="mb-1">Email</h4>
                <a href="mailto:cs@mynameisdav.com">cs@mynameisdav.com</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- MODAL NEWS --> */}
      <div className="modal fade" id="newsModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="newsModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="detail-news">
              <div className="ui-container position-relative">
                <div className="ornament float-end position-relative">
                  <img src={require("../assets/img/ornament_blue.png")} alt=""/>
                  <span data-bs-dismiss="modal" className="close-x position-absolute top-50 start-50 translate-middle">X</span>
                </div>
                <div className="py-md-5 py-3">
                  <h2 className="mb-md-5 mb-3">{translatedData.worksHome.modalNews.title}</h2>
                  <img src={require("../assets/img/header_news.png")} className="w-100" alt=""/>
                </div>
              </div>
              <div className="ui-container text-news position-relative">
                <div className="ornament float-end">
                  <img src={require("../assets/img/ornament_white.png")} alt=""/>
                </div>
                <div className="py-5">
                  <p className="mb-3">
                    {translatedData.worksHome.modalNews.desc[0]}
                  </p>
                  <p className="mb-3">
                    {translatedData.worksHome.modalNews.desc[1]}
                  </p>
                  <p className="mb-3">
                    {translatedData.worksHome.modalNews.desc[2]}
                  </p>
                  <p className="source">{translatedData.worksHome.modalNews.desc[3]}: <a href={translatedData.worksHome.modalNews.desc[4]} target="_blank">{translatedData.worksHome.modalNews.desc[4]}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- MODAL VIDEO --> */}
      <div className="modal fade" id="videoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-video">
              <div data-bs-dismiss="modal" className="mb-3 me-0 ms-auto close-x d-flex justify-content-center align-items-center" onClick={()=>setShowVideo(false)}>X</div>
              <div className="ratio ratio-16x9">
                <iframe src={`${showVideo ? urlVideo+"?autoplay=0" : ""}`} allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
