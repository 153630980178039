import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.scss';
import Layout from './components/elements/Layout';
import Home from './components/Home';
import Location from './components/Location';
import MultilingualTranslator from './components/elements/MultilingualTranslator';
import PageNotFound from './components/PageNotFound';

function App() {
  return (
    <BrowserRouter>
      <MultilingualTranslator>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/location" element={<Location />} />
            <Route path="/id" element={<Home />} />
            <Route path="/id/location" element={<Location />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Layout>
      </MultilingualTranslator>
    </BrowserRouter>
  );
}

export default App;
