import { Helmet } from 'react-helmet-async';
import Footer from './Footer';
import Navbar from './Navbar';

function Layout({ children }) {
  return (
    <>
      {/* <Helmet>
        <title>DAV</title>
        <meta name="description" content="DAV" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet> */}
      <main>
        <Navbar />
        {children}
        <Footer />
      </main>
    </>
  );
}

export default Layout;
