import { useLocation, useNavigate } from "react-router-dom";
import {useEffect, useState} from 'react';
import { LanguageContext } from "../../lib/multilingualContext";
import en from "../../lib/locales/en";
import id from "../../lib/locales/id";

export default function MultilingualTranslator ({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [translatedData, setTranslatedData] = useState(en);
  const [currentLang, setCurrentLang] = useState("en");

  useEffect(() => {
    let lang = "";
    if(location.pathname.split('/')[1] === "id"){
      setTranslatedData(id);
      lang = "id"
    }else{
      setTranslatedData(en);
      lang = "en"
    }
    setCurrentLang(lang);
  }, [location]);
  
  function buildPath(theArray){     
    var newPathname = "";
    for (let i = 0; i < theArray.length; i++) {
      newPathname += "/";
      newPathname += theArray[i];
    }
    return newPathname;
  }  

  function toggleLanguage(nextLang) {
    if(nextLang !== currentLang){
      setCurrentLang(nextLang);
      let pathArray = location.pathname.split('/');
      if(nextLang === "id"){
        pathArray[0] = "id";
      }else{
        pathArray.splice(0,2);
      }
  
      let nextPath = buildPath(pathArray);
      
      navigate(nextPath === ""? "/" : nextPath);
    }
  }

  return (
    <LanguageContext.Provider value={{currentLang, translatedData, toggleLanguage}}>
      {children}
    </LanguageContext.Provider>
  )
}