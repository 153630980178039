import { useEffect, useState, useContext } from 'react';
import { LanguageContext } from '../lib/multilingualContext';

export default function Home() {
  const { translatedData } = useContext(LanguageContext);
  const [arrTable, setArrTable] = useState([]);

  function renderTableCarousel() {
    let arrFile = [];
    const nFile = 1222;
    const nRange = 25;

    for (var i = 1; i <= nFile - (nFile % (nRange * 2)); i = i + nRange * 2) {
      var arrItem = [];
      arrItem = [
        i + ' - ' + (i + (nRange - 1)),
        i + nRange + ' - ' + (i + (nRange * 2 - 1)),
      ];
      arrFile.push(arrItem);
    }

    arrFile.push([nFile - (nFile % (nRange * 2)) + 1 + ' - ' + nFile]);

    setArrTable(arrFile);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    renderTableCarousel();
  }, []);

  return (
    <>
      <div className="location-jombotron"></div>
      <div className="location-detail position-relative">
        <div className="ornament position-absolute">
          <img src={require('../assets/img/ornament_black.png')} alt="" />
        </div>
        <div className="ui-container">
          <div className="row pt-4">
            <div className="col-md-5 col-12 ct-left">
              <h3 className="mb-4 mt-4">
                {translatedData.locationDetail.title}
              </h3>
              <p className="mb-3">{translatedData.locationDetail.desc}</p>
            </div>
            <div className="col-md-7 col-12 ct-right">
              <img
                className="mx-auto ms-md-0 d-block"
                src={require('../assets/img/device2.png')}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="location-table position-relative">
        <div className="ornament position-absolute">
          <img src={require('../assets/img/ornament_purple.png')} alt="" />
        </div>
        <div className="ui-container table-slider-wrap">
          <div className="title-table">
            <div className="d-flex justify-content-center align-items-center mb-md-5 mb-4">
              <img
                className="logo-table me-4"
                src={require('../assets/img/logo_alfamart.png')}
                alt=""
              />
              <img
                className="logo-table"
                src={require('../assets/img/logo_alfamidi.png')}
                alt=""
              />
            </div>
          </div>
          <div
            id="tableCarousel"
            data-bs-interval="false"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              {arrTable.map((item, key) => (
                <div
                  key={key}
                  className={`carousel-item ${key === 0 && 'active'}`}
                >
                  <div className="table-slide-item mx-auto">
                    <div className="row g-2">
                      <div className="col-6">
                        <img
                          className="content-table"
                          src={require(`../assets/img/table/${item[0]}.png?ver=1.1`)}
                          alt=""
                        />
                      </div>
                      {item[1] ? (
                        <div className="col-6">
                          <img
                            className="content-table"
                            src={require(`../assets/img/table/${item[1]}.png?ver=1.1`)}
                            alt=""
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#tableCarousel"
              data-bs-slide="prev"
            >
              <img src={require('../assets/img/right-arrow.png')} alt="" />
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#tableCarousel"
              data-bs-slide="next"
            >
              <img src={require('../assets/img/right-arrow.png')} alt="" />
            </button>
          </div>
          <p className="mt-md-5 mt-4 text-center">
            {translatedData.locationDetail.titleUpdate}
            <b>{translatedData.locationDetail.dateUpdate}</b>{' '}
            <span className="star">*</span>
          </p>
          <p className="mt-2 text-center note">
            *{translatedData.locationDetail.note}
          </p>
        </div>
      </div>
    </>
  );
}
