const idData = {
  //NAVBAR
  navBar: {
    navLogoUrl: '/id/',
    nav: [
      { name: 'CARA KERJA DAN BERITA', url: '/id/#how-it-works-and-news' },
      { name: 'LOKASI', url: '/id/location' },
      { name: 'PARTNER KAMI', url: '/id/#brands' },
      { name: 'KONTAK', url: '/id/#contact' },
    ],
  },
  //SERVICES
  servicesHome: {
    title:
      'DÄV adalah penyedia platform IoT terkemuka yang memanfaatkan teknologi aplikasi Augmented Reality (AR) + Artificial Intelligence (AI) + Internet of Things (IoT)',
    desc: [
      'Tujuan kami adalah untuk memberikan solusi melalui kios kami yang mencakup berbagai skenario di kota ke-2 hingga ke-4 dan juga menjangkau daerah pinggiran kota di Indonesia.',
      'DÄV, dengan teknologi yang telah dipatenkan telah bermitra dengan peritel terkemuka di Indonesia dan melayani merek global.',
    ],
  },
  //WORKS
  worksHome: {
    title: 'CARA KERJA DAN BERITA',
    tutorialBtn: 'CARA KERJA',
    newsBtn: 'BERITA',
    modalNews: {
      title:
        'BAF Kiosk Tawarkan Pengajuan Pembiayaan secara Praktis dan Informatif',
      desc: [
        'Perkembangan ekonomi digital Indonesia telah mengubah perilaku konsumen. Salah satunya,kecenderungan masyarakat dalam menggunakan platform digital. Untuk menjawab kebutuhan masyarakat, PT Bussan Auto Finance (BAF) bekerjasama dengan DAV di 232 titik di pulau Jawa, untuk turut mempermudah calon konsumen untuk dapat mengajukan produk impian. Kehadiran BAF di DAV akan menawarkan pengalaman pengajuan pembiayaan secara praktis dan informatif.',
        'Khusus 2.000 calon konsumen pertama yang mengajukan pembiayaan dan mencantumkan data valid melalui DAV, otomatis akan mendapatkan voucher belanja Alfamart senilai Rp 25.000 selama periode program. Tak hanya itu, bagi calon konsumen yang mengajukan pembiayaan melalui BAF Kiosk dan disetujui akan berkesempatan mendapatkan tambahan cashback BAF Points senilai total Rp 50 juta.',
        'Adapun promo menarik tersebut hanya berlaku untuk pengajuan melalui DAV, Apabila tertarik mendapatkan promo menarik tersebut, konsumen cukup mengajukan pembiayaan pada mesin DAV di gerai Alfamart terdekat.',
        'Sumber',
        'https://surabaya.tribunnews.com/2022/11/11/baf-kiosk-tawarkan-pengajuan-pembiayaan-secara-praktis-dan-informatif',
      ],
    },
  },
  //LOCATIONS
  locationHome: {
    title: 'LOKASI',
    desc: 'DAV telah berevolusi menjadi solusi kios digital all-in-one yang memberikan keterlibatan pembeli, efisiensi operasional  di berbagai lokasi publik seperti minimarket, hotel, apotek, bandara. DAV adalah enabler O2O antara Brand dan kebutuhan Konsumen.',
    subTitle: 'Temukan Kami di',
    btnDetail: 'btn_location_id.png',
    urlDetail: '/id/location',
  },
  locationDetail: {
    title: 'DETAIL LOKASI',
    desc: 'DAV telah berevolusi menjadi solusi kios digital all-in-one yang memberikan keterlibatan pembeli, efisiensi operasional  di berbagai lokasi publik seperti minimarket, hotel, apotek, bandara. DAV adalah enabler O2O antara Brand dan kebutuhan Konsumen.',
    titleUpdate: 'Update terakhir : ',
    dateUpdate: '11 September 2023',
    note: 'Daftar lokasi akan ditambahan secara berkala',
  },
  //CLIENT
  clientHome: {
    title: 'PARTNER KAMI',
  },
  //CONTACT
  contactHome: {
    title: 'KONTAK KAMI',
    titleAddress: 'Alamat',
    titlePhone: 'Telepon',
  },
};

export default idData;
